@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --dark: #1a1a1a;
  --light: #FFFFFF;
  --blue: #3b82f666;
  --dark-blue: #3b83f683;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--light);
  background-color: var(--dark);
}

#home,
#speakers,
#contact,
#list {
  scroll-margin-top: 120px;
}

#theme {
  background: linear-gradient(to top left, #b0afaf66, #807676, rgba(102, 155, 235, 0.726), #e99f9f, #d7679f66) no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  z-index: 500;
}

#tsparticles {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}


::-webkit-scrollbar {
  width: 0.75em;
}

::-webkit-scrollbar-track {
  border-radius: 50%;
  background: var(--dark);
}

::-webkit-scrollbar-thumb {
  background: var(--blue);
  border-radius: 100vw;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--dark-blue);
}

@media screen and (prefers-reduced-motion: reduce) {
  * {
    scroll-behavior: auto;
  }
}